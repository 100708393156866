import React, {useEffect, useState}  from 'react'

import FormControl from '@material-ui/core/FormControl';
import {Container, Badge, Card, Button, ButtonGroup, Row, Col} from 'react-bootstrap';
import {apiQuickSubscriptionEvent, apiEventsDetail} from './lookup'
import {CampEventDetailInformation} from './detail_camp'
import {ScheduleChoice, CSRFToken, EventDescription} from './components_page'

export function SubscriptionOption(props) {
	const {camp, events, event_selected, locations, location_selected, kids, kid_selected } = props
	console.log(events)

}

export function QuickSubscription(props) {
	const [pendingAPIcall, setPendingAPIcall] = useState(false)
	const [pendingAPIcallDisplay, setPendingAPIcallDisplay] = useState(true)
	//const [subscriptionDone, setSubscriptionDone] = useState(false)
	const [subscriptionStatus, setSubscriptionStatus] = useState()
	const [eventDetailData, setEventDetailData] = useState()
	const {eventId} = props
	const [formData, setFormData] = useState({
		email: '',
		first_name: '',
		last_name: '',
		free_comments: '',
		//schedule: 'WEEK_DAY',
		//payment_type : 'BANK_TRANSFER'
	});
	const [scheduleSelected, setScheduleSelected] = useState()
	const [scheduleSelectedText, setScheduleSelectedText] = useState()
	const [totalPrice, setTotalPrice] = useState(0.0)
	const [paymentType, setPaymentType] = useState()

	const handleSubmitResponse = (response) =>{
		setSubscriptionStatus(response)
		setPendingAPIcall(false)
		//setSubscriptionDone(true)
		//if (status === 200){
		//	setSubscriptionStatus(response.message)
		//	console.log("Event description " + response.message)
		//	//console.log(
		//	//	Object.entries(response[0])
		//	//	.map( ([key, value]) => `${key}: ${value}` )
		// 	// )
//
		//} else {
		//	setSubscriptionStatus("Il y a eu un problème lors de l'inscription. Veuillez nous contacter à hello@famio.be")
		//	//setSubscriptionStatus("Il y a eu un problème lors de l'inscription")
		//	setPendingAPIcall(false)
		//	setSubscriptionDone(true)
		//}
	}

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			let first_payement_type_available = response.schedule_price_unique.payment_method_array[Object.keys(response.schedule_price_unique.payment_method_array)[0]]
			let first_schedule_available = response.schedule_price_unique.schedule_array[Object.keys(response.schedule_price_unique.schedule_array)[0]]
			setEventDetailData(response)
			setScheduleSelected(first_schedule_available)
			//setTotalPrice(response.schedule_price_unique.schedule_button[0]["price"])
			setTotalPrice(first_schedule_available["price"])
			setPaymentType(first_payement_type_available)
			
			// Update formData with initial value.
			setFormData((prevData) => ({
				...prevData,
				["schedule"]: first_schedule_available.ref,
				["payment_type"]: first_payement_type_available.ref,
				}));
			setPendingAPIcallDisplay(false)
		} else {
			alert("There was an error")
		}
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		apiQuickSubscriptionEvent(eventId, formData, setSubscriptionStatus, handleSubmitResponse)
		setPendingAPIcall(true)
	};

	useEffect(() => {
		apiEventsDetail(eventId, handleViewResponse)
		setPendingAPIcallDisplay(true)
	}, [])

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
		...prevData,
		[name]: value,
		}));
	};

	// style={{ width: '22rem' }}>
	//						<Col className="text-right">
	//<label htmlFor="email">Email</label> 
	//</Col>
	return <React.Fragment>
		<Container>
			<Card className="card shadow mb-4 mx-2">
				<Container>
					<Card.Title><strong>Inscription rapide</strong></Card.Title>
					{pendingAPIcallDisplay ? (<div>Chargement...</div>)
					:(
						<div>
							<CampEventDetailInformation
								event={eventDetailData}
								key={`detail-card-${eventDetailData.id}`}
							/>
							<Card className="card shadow mt-2 text-center">
								<h2 className="text-info"><center><strong>Horaires et options</strong></center></h2><br />
								<ScheduleChoice
									schedule={eventDetailData.schedule_price_unique} 
									organizer={eventDetailData.stage.organizer} 
									scheduleSelected={scheduleSelected}
									setScheduleSelected={setScheduleSelected}
									totalPrice={totalPrice}
									setTotalPrice={setTotalPrice}
									scheduleSelectedText={scheduleSelectedText}
									setScheduleSelectedText={setScheduleSelectedText}
									paymentType={paymentType}
									setPaymentType={setPaymentType}
									formData={formData}
									setFormData={setFormData}
								/>
							</Card>
						</div>
					)}

					<Card className="card shadow mt-2 text-center">
						<Card.Title><h2 className="text-info"><center><strong>Inscription</strong></center></h2></Card.Title>
						{pendingAPIcall ? (<div>Inscription en cours...</div>)
						:(<div>
							{subscriptionStatus ? (<div>
									{subscriptionStatus.message}
									{subscriptionStatus.status == "success" ? (
										<div>
											<br />{subscriptionStatus.message_payment}
										</div>
									):(<div></div>)}
								</div>)
							:(<div>
									<FormControl>
										<form onSubmit={handleSubmit} method="POST">
											
											<Row>

												<Col className="text-center">
													<input type="email" name="email" value={formData.email} className='btn btn-outline-info' placeholder="Email" onChange={handleInputChange} required /><br />
													
												</Col>
											</Row>
											<Row className="mt-2">
												<Col className="text-center">
													<input type="text" name="first_name" value={formData.first_name} className='btn btn-outline-info' placeholder="Prénom" onChange={handleInputChange} required /><br />
												</Col>
											</Row>
											<Row className="mt-2">
												<Col className="text-center">
												<input type="text" name="last_name" value={formData.last_name} className='btn btn-outline-info' placeholder="Nom" onChange={handleInputChange} required /><br />
												</Col>
											</Row>
											<Row className="mt-2">
												<Col className="text-center">
													<textarea name="free_comments" value={formData.free_comments} className='btn btn-outline-info' placeholder="Commentaire" onChange={handleInputChange} /><br />
												</Col>
											</Row>
											<Row className="mt-2">
												<Col className="text-center">
													<button type="submit" className="btn btn-outline-info">S'inscrire</button>
												</Col>
											</Row>
										</form>
									</FormControl>
								</div>)}
							</div>
						)}
					</Card>
				</Container>
			</Card>
		</Container>
	</React.Fragment>;
};
