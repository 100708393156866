import React, {useEffect, useState}  from 'react'
import Form from 'react-bootstrap/Form'

import {
	apiScheduleDetail,
	apiEventsDetail,
} from './lookup'


function DisplaySchedule(props) { 
	const {schedule} = props

	return <React.Fragment>
			<div>
				{schedule.schedule} ({schedule.price} Euro)
			</div>
				<Form.Check
					enabled
					type='radio'
					name="schedule_choice"
					label={schedule.schedule}
					id={schedule.schedule}
				/>
				{schedule.family_member_discount_active ? (<div>Réduction pour la famille</div>): (<div>Pas de réduction pour la famille</div>)}
		</React.Fragment>
}
export function EnrollKid(props) {
	const [pendingAPIcall, setPendingAPIcall] = useState(false)
	const [schedulePrice, setSchedulePrice] = useState([])
	const [eventDetail, setEventDetail] = useState([])
	const {eventId} = props

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			setEventDetail(response)
			setSchedulePrice(response.schedule_price)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}
	useEffect(() => {
		apiEventsDetail(eventId, handleViewResponse)
		setPendingAPIcall(true)
	}, [])

	return <React.Fragment>
			<div>
				{pendingAPIcall ? (
					<div>Loading...</div>
				): (<div>
					<Form>
						{schedulePrice.map((item, index) => {
					return <div className="col" key={`${index}-${item.id}`}>
								<DisplaySchedule
									schedule = {item}
								/>
							</div>
						})}
					</Form>
					</div>
				) }
			</div>
		</React.Fragment>
}