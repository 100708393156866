import React, {useEffect, useState}  from 'react'
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/Select';

import {
	apiCampDetail,
	apiCampsList,
	apiCampsListAll,
	apiCampsPerOrganizer,
	apiPeriodsUpcoming,
	apiSearchOptions,
	apiWorkshopListFilter,
	apiSearchOptionsWorkshop,
} from './lookup'
import {CampDetailCard, CampsTableView, CampsDetailTable} from './detail_camp.js'

const useStyles = makeStyles((theme) => ({
	formControl: {
	  margin: theme.spacing(1),
	  minWidth: 120,
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
}));

export function TableHeading(props) {
	const [periodsUpcoming, setPeriodsUpcoming] = useState()
	const [pendingAPIcall, setPendingAPIcall] = useState(true)

	// Get the period directly from the Search Bar to avoid double API call
	// for the same organizer periods.
	const {searchBarPeriod} = props

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			setPeriodsUpcoming(response)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}
	useEffect(() => {
		apiPeriodsUpcoming(handleViewResponse)
		setPendingAPIcall(true)
	}, [])
	return	<React.Fragment>
					<tr>
						<th className="text-info">Stage</th>
						{searchBarPeriod.map((item, index) => {
							return <th className="text-info">{item.get_date}</th>
						})}
				</tr>
	</React.Fragment>
}


export function WorkshopSearch(props) {
	const classes = useStyles();

	const [camps, setCamps] = useState([])
	const [pendingAPIcall, setPendingAPIcall] = useState(false)

	const [searchViewType, setSearchViewType] = useState("card")	
	const [displaySearchBar, setDisplaySearchBar] = useState(true)

	const [age, setAge] = useState('')
	const [searchBarAge, setSearchBarAge] = useState([])
	const [organizer, setOrganizer] = useState('')
	const [searchBarOrganizer, setSearchBarOrganizer] = useState([])
	const [period, setPeriod] = useState('')
	
	const [searchDate, setSearchDate] = useState('')
	
	const [searchBarPeriod, setSearchBarPeriod] = useState([])
	const [category, setCategory] = useState('')
	const [searchBarCategory, setSearchBarCategory] = useState([])
	const [pendingAPIcallSearchBar, setPendingAPIcallSearchBar] = useState(false)

	//const [filters, setFilters] = useState([])
	// Used with paginations
	const [nextUrl, setNextUrl] = useState(null)
	
	// If the organizerId is specified, only show the camps of that organizer
	const {searchBar, organizerId} = props

	let showSearchBar = (searchBar === "false" ? false : {displaySearchBar})

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			// With paginations, the results must be retrieved
			setCamps(response.results)
			setNextUrl(response.next)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}
	const handleViewResponseSearchBar = (response, status) =>{
		if (status === 200){
			setSearchBarAge(response.selected_ages)
			setSearchBarOrganizer(response.organizer)
			setSearchBarPeriod(response.periods)
			setSearchBarCategory(response.categories)
			setPendingAPIcallSearchBar(false)
		} else {
			alert("There was an error")
		}
	}
	useEffect(() => {
		//apiSearchOptions(handleViewResponseSearchBar)

		if (organizerId > 0) {
			// A complete organizer object can't be set here, only the id is enough to display only for the 
			// given organizer.
			apiSearchOptionsWorkshop(handleViewResponseSearchBar, "?organizer_id="+organizerId)
			setPendingAPIcallSearchBar(true)
			setOrganizer({"id": organizerId})
			makeApiCallFilter(age, period.id, organizerId, category.id)
		} else {
			apiSearchOptionsWorkshop(handleViewResponseSearchBar, "?organizer_id="+organizer.id)
			setPendingAPIcallSearchBar(true)
			// Make a generic API call but all the parameters are undefined or empty.
			makeApiCallFilter(age, period.id, organizer.id, category.id)
		}
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log("oranizer object " + JSON.stringify(organizer))
		}
	}, [])

	const handleClickTableView = (event) => {
		setSearchViewType("table")
	}
	const handleClickCardView = (event) => {
		setSearchViewType("card")
	}

	function makeApiCallFilter(filter_age, filter_period_id, filter_organizer_id, filter_category_id, filter_date) {
		//apiCampsListFilter(handleViewResponse, "?age="+filter_age+"&period_id="+filter_period_id+"&organizer_id="+filter_organizer_id+"&category_id="+filter_category_id)
		apiWorkshopListFilter(handleViewResponse, "?age="+filter_age+"&period_id="+filter_period_id+"&organizer_id="+filter_organizer_id+"&category_id="+filter_category_id+"&date="+filter_date)
		setPendingAPIcall(true)
	}

	const handleChangeFilterAge = (event) => {
		setAge(event.target.value)
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log("age selected " + JSON.stringify(event.target.value))
		}
		makeApiCallFilter(event.target.value, period.id, organizer.id, category.id, searchDate)
	}
	const handleChangeFilterOrganizer = (event) => {
		setOrganizer(event.target.value)
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log("organizer selected " + JSON.stringify(event.target.value))
		}
		makeApiCallFilter(age, period.id, event.target.value.id, category.id, searchDate)
	}
	const handleChangeFilterPeriod = (event) => {
		setPeriod(event.target.value)
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log("period selected " + JSON.stringify(event.target.value))
		}
		makeApiCallFilter(age, event.target.value.id, organizer.id, category.id, searchDate)
	}
	const handleChangeFilterDate = (event) => {
		setSearchDate(event.target.value)
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log("date selected " + JSON.stringify(event.target.value))
		}
		makeApiCallFilter(age, period.id, organizer.id, category.id, event.target.value)
	}
	const handleChangeFilterCategory = (event) => {
		setCategory(event.target.value)
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log("category selected " + JSON.stringify(event.target.value))
		}
		makeApiCallFilter(age, period.id, organizer.id, event.target.value.id, searchDate)
	}
	

	// When clicking Load Next, for now, itnis only the active camps for all organizer
	const handleLoadNext = (event) => {
		event.preventDefault()
		if (nextUrl !== null) {
		  const handleLoadNextResponse = (response, status) =>{
			if (status === 200){
			  setNextUrl(response.next)
			  const newCamps = [...camps].concat(response.results)
			  setCamps(newCamps)
			  setPendingAPIcall(false)
			}
		  }
		  makeApiCallFilter(age, period.id, organizer.id, category.id)
		  //apiCampsList(handleLoadNextResponse, nextUrl)
		  setPendingAPIcall(true)
		}
	}
	function getCoords(lat, lng){
		console.log(lat, lng);
	}

	return <React.Fragment>
		 {showSearchBar ? (
			<div className="row">	
				<div className="col">
					{pendingAPIcallSearchBar ? (<div>Loading...</div>)
					:( 
					<div>
						<div className="row">
							<div className="col-auto">
								<FormControl className={classes.formControl}>
									<InputLabel id="select-age-label">Age</InputLabel>
									<Select
									labelId="select-age-label"
									id="select-age"
									value={age}
									onChange={handleChangeFilterAge}
									>
									<MenuItem value="">Tous les âges</MenuItem>
									{searchBarAge.map((item, index) => {
										return <MenuItem value={item[0]}>{item[1]}</MenuItem>
									})}
									</Select>
								</FormControl>
							</div>
							<div className="col-auto">
								<FormControl className={classes.formControl}>
									<InputLabel id="select-category-label">Catégories</InputLabel>
									<Select
									labelId="select-category-label"
									id="select-category"
									value={category.name_fr}
									onChange={handleChangeFilterCategory}
									>
									<MenuItem value="">Toutes les catégories</MenuItem>
									{searchBarCategory.map((item, index) => {
										return <MenuItem value={item}>{item.name_fr}</MenuItem>
									})}
									</Select>
								</FormControl>
							</div>
							<div className="col-auto mt-4">
								Quand ?
								<Form.Control
									type="date"
									name="date"
									placeholder="Date souhaitée"
									onChange={handleChangeFilterDate}
								/>
							</div>
						</div>
					</div>
					)}
				</div>
			</div>
		 ) : (<div></div>)
		}
			<div className="row">	
				<div className="col mr-auto">
					<button onClick={handleClickTableView} className='btn btn-sm btn-outline-info'>Vue par date</button>
					<button onClick={handleClickCardView} className='btn btn-sm btn-outline-info'>Vue par atelier</button>
				</div>
				<div className="col-auto">
					<Badge pill variant="success">
						Disponible
					</Badge>
					<Badge pill variant="warning">
						Liste d'attente
					</Badge>
				</div>
			</div>
		{pendingAPIcall ? (
			<div>Loading...</div>
		): (
			<div>
				<DisplaySearchedCamps
					camps = {camps}
					searchViewType = {searchViewType}
					searchBarPeriod = {searchBarPeriod}
					/>
				{nextUrl !== null && <button onClick={handleLoadNext} className='btn btn-outline-primary'>Afficher plus de résultats</button>}
			</div>
		) }
	</React.Fragment>
}


export function DisplaySearchedCamps(props) {
	const {camps, searchViewType, searchBarPeriod} = props

	let isViewCard = (searchViewType === "card" ? true : false)

	return <React.Fragment>
		{isViewCard ? (
			<div className="row">
				{camps.map((item, index) => {
					return <div className="col" key={`${index}-${item.id}`}>
								<CampDetailCard
								camp={item} 
								className='my-5 py-5' 
								key={`${index}-${item.id}`} 
								/>
							</div>
				})}
			</div>
			) : (<div className="row">
					<div className="col">
						<Table striped bordered hover>
							<thead>
								<TableHeading 
								searchBarPeriod={searchBarPeriod}
								/>
							</thead>
							<tbody>
								{camps.map((item, index) => {
									return <CampsDetailTable
												camp = {item} />
										
								})}
							</tbody>
						</Table>
					</div>
				</div>
			)
		}
	</React.Fragment>
}


/**
 * 
 * No organizer choice for now, as the organizer is selected.
 * 						<FormControl className={classes.formControl}>
							<InputLabel id="select-organizer-label">Organisateur</InputLabel>
							<Select
							labelId="select-organizer-label"
							id="select-organizer"
							value={organizer.name}
							onChange={handleChangeFilterOrganizer}
							>
							<MenuItem value="">Tous les organisateurs</MenuItem>
							{searchBarOrganizer.map((item, index) => {
								return <MenuItem value={item}>{item.name}</MenuItem>
							})}
							</Select>
						</FormControl>
 * 
 */