import React, {useEffect, useState}  from 'react'
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/Select';

import {
	apiActivitiesListFilter,
	apiSearchOptionsActivities,
} from './lookup'


// TO CHECK IF CAN BE REUSED/NEEDED FOR ACTIVITIES
import {
	CampsDetailTable,
} from './detail_camp'
import {
	TableHeading,
} from './display_camps'
import {
	ActivityDetailCard,
	ActivityDetailTable,
} from './detail_activity'
//

import {
	useStyles
} from './famio'

//import {CampDetailCard, ActivitiesTableView, ActivitiesDetailTable} from './detail_camp.js'

export function ActivitiesSearch(props) {
	const classes = useStyles();

	const [activities, setActivities] = useState([])
	const [pendingAPIcall, setPendingAPIcall] = useState(false)

	const [searchViewType, setSearchViewType] = useState("card")	
	const [displaySearchBar, setDisplaySearchBar] = useState(true)

	const [age, setAge] = useState('')
	const [searchBarAge, setSearchBarAge] = useState([])
	const [organizer, setOrganizer] = useState('')
	const [searchBarOrganizer, setSearchBarOrganizer] = useState([])
	const [day, setDay] = useState('')
	const [searchBarPeriod, setSearchBarPeriod] = useState([])
	const [category, setCategory] = useState('')
	const [searchBarCategory, setSearchBarCategory] = useState([])
	const [pendingAPIcallSearchBar, setPendingAPIcallSearchBar] = useState(false)

	//const [filters, setFilters] = useState([])
	// Used with paginations
	const [nextUrl, setNextUrl] = useState(null)
	
	// If the organizerId is specified, only show the activities of that organizer
	const {searchBar, organizerId} = props

	let showSearchBar = (searchBar === "false" ? false : {displaySearchBar})

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			// With paginations, the results must be retrieved
			setActivities(response.results)
			setNextUrl(response.next)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}
	const handleViewResponseSearchBar = (response, status) =>{
		if (status === 200){
			setSearchBarAge(response.selected_ages)
			setSearchBarOrganizer(response.organizer)
			//setSearchBarPeriod(response.periods)
			setSearchBarCategory(response.categories)
			setPendingAPIcallSearchBar(false)
		} else {
			alert("There was an error")
		}
	}
	useEffect(() => {

		if (organizerId > 0) {
			apiSearchOptionsActivities(handleViewResponseSearchBar, "?organizer_id="+organizerId)
			setPendingAPIcallSearchBar(true)
			// A complete organizer object can't be set here, only the id is enough to display only for the 
			// given organizer.
			setOrganizer({"id": organizerId})
			makeApiCallFilter(age, day, organizerId, category.id)
		} else {
			apiSearchOptionsActivities(handleViewResponseSearchBar, "?organizer_id="+organizer.id)
			setPendingAPIcallSearchBar(true)
			// Make a generic API call but all the parameters are undefined or empty.
			makeApiCallFilter(age, day, organizer.id, category.id)
		}
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log("oranizer object " + JSON.stringify(organizer))
		}
	}, [])

	const handleClickTableView = (event) => {
		setSearchViewType("table")
	}
	const handleClickCardView = (event) => {
		setSearchViewType("card")
	}

	function makeApiCallFilter(filter_age, filter_day, filter_organizer_id, filter_category_id) {
		apiActivitiesListFilter(handleViewResponse, "?age="+filter_age+"&day="+filter_day+"&organizer_id="+filter_organizer_id+"&category_id="+filter_category_id)
		setPendingAPIcall(true)
	}

	const handleChangeFilterAge = (event) => {
		setAge(event.target.value)
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log("age selected " + JSON.stringify(event.target.value))
		}
		makeApiCallFilter(event.target.value, day, organizer.id, category.id)
	}
	const handleChangeFilterOrganizer = (event) => {
		setOrganizer(event.target.value)
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log("organizer selected " + JSON.stringify(event.target.value))
		}
		makeApiCallFilter(age, day, event.target.value.id, category.id)
	}
	const handleChangeFilterDay = (event) => {
		setDay(event.target.value)
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log("day selected " + JSON.stringify(event.target.value))
		}
		makeApiCallFilter(age, event.target.value, organizer.id, category.id)
	}
	const handleChangeFilterCategory = (event) => {
		setCategory(event.target.value)
		if (process.env.REACT_APP_DEBUG === 'true') {
			console.log("category selected " + JSON.stringify(event.target.value))
		}
		makeApiCallFilter(age, day, organizer.id, event.target.value.id)
	}
	

	// When clicking Load Next, for now, itnis only the active activities for all organizer
	const handleLoadNext = (event) => {
		event.preventDefault()
		if (nextUrl !== null) {
		  const handleLoadNextResponse = (response, status) =>{
			if (status === 200){
			  setNextUrl(response.next)
			  const newActivities = [...activities].concat(response.results)
			  setActivities(newActivities)
			  setPendingAPIcall(false)
			}
		  }
		  makeApiCallFilter(age, day, organizer.id, category.id)
		  //apiActivitiesList(handleLoadNextResponse, nextUrl)
		  setPendingAPIcall(true)
		}
	}
	function getCoords(lat, lng){
		console.log(lat, lng);
	}

	return <React.Fragment>
		 {showSearchBar ? (
			<div className="row">	
				<div className="col">
					{pendingAPIcallSearchBar ? (<div>Loading...</div>)
					:( 
					<div>
						<FormControl className={classes.formControl}>
							<InputLabel id="select-age-label">Age</InputLabel>
							<Select
							labelId="select-age-label"
							id="select-age"
							value={age}
							onChange={handleChangeFilterAge}
							>
							<MenuItem value="">Tous les âges</MenuItem>
							{searchBarAge.map((item, index) => {
								return <MenuItem value={item[0]}>{item[1]}</MenuItem>
							})}
							</Select>
						</FormControl>
						<FormControl className={classes.formControl}>
							<InputLabel id="select-day-label">Jours</InputLabel>
							<Select
							labelId="select-day-label"
							id="select-day"
							value={day}
							onChange={handleChangeFilterDay}
							>
							<MenuItem value="">Tous les jours</MenuItem>
							<MenuItem value="1_MONDAY">Lundi</MenuItem>
							<MenuItem value="2_TUESDAY">Mardi</MenuItem>
							<MenuItem value="3_WEDNESDAY">Mercredi</MenuItem>
							<MenuItem value="4_THURSDAY">Jeudi</MenuItem>
							<MenuItem value="5_FRIDAY">Vendredi</MenuItem>
							<MenuItem value="6_SATURDAY">Samedi</MenuItem>
							<MenuItem value="7_SUNDAY">Dimanche</MenuItem>
							</Select>
						</FormControl>
						<FormControl className={classes.formControl}>
							<InputLabel id="select-category-label">Catégories</InputLabel>
							<Select
							labelId="select-category-label"
							id="select-category"
							value={category.name_fr}
							onChange={handleChangeFilterCategory}
							>
							<MenuItem value="">Toutes les catégories</MenuItem>
							{searchBarCategory.map((item, index) => {
								return <MenuItem value={item}>{item.name_fr}</MenuItem>
							})}
							</Select>
						</FormControl>
					</div>
					)}
				</div>
			</div>
		 ) : (<div></div>)
		}
			<div className="row">	
				<div className="col mr-auto">
					<button onClick={handleClickTableView} className='btn btn-sm btn-outline-info'>Vue par horaire</button>
					<button onClick={handleClickCardView} className='btn btn-sm btn-outline-info'>Vue par activité</button>
				</div>
				<div className="col-auto">
					<Badge pill variant="success">
						Disponible
					</Badge>
					<Badge pill variant="warning">
						Liste d'attente
					</Badge>
				</div>
			</div>
		{pendingAPIcall ? (
			<div>Loading...</div>
		): (
			<div>
				<DisplaySearchedActivities
					activities = {activities}
					searchViewType = {searchViewType}
					/>
				{nextUrl !== null && <button onClick={handleLoadNext} className='btn btn-outline-primary'>Afficher plus de résultats</button>}
			</div>
		) }
	</React.Fragment>
}

export function DisplaySearchedActivities(props) {
	const {activities, searchViewType} = props

	let isViewCard = (searchViewType === "card" ? true : false)

	return <React.Fragment>
		{isViewCard ? (
			<div className="row">
				{activities.map((item, index) => {
					return <div className="col" key={`${index}-${item.id}`}>
								<ActivityDetailCard
								activity={item} 
								className='my-5 py-5' 
								key={`${index}-${item.id}`} 
								/>
							</div>
				})}
			</div>
			) : (
				<Table striped bordered hover>
					<thead>
						<ActivityTableHeading />
					</thead>
					<tbody>
					{activities.map((item, index) => {
						return <ActivityDetailTable
									activity = {item} />
							
					})}
					</tbody>
				</Table>
			)
		}
	</React.Fragment>
}




export function ActivityTableHeading(props) {
	//const [dayOfWeek, setDayOfWeek] = useState()
	const [pendingAPIcall, setPendingAPIcall] = useState(true)
	const dayOfWeek = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]
	/**useEffect(() => {
		apiPeriodsUpcoming(handleViewResponse)
		apiPeriodsUpcoming(handleViewResponse)
		
		setPendingAPIcall(true)
	}, [])
	**/
	return <React.Fragment>
				<tr>
						<th className="text-info">Activités</th>
						{dayOfWeek.map((item, index) => {
							return <th className="text-info">{item}</th>
						})}
				</tr>
	</React.Fragment>
}