import React, {useEffect, useState}  from 'react'
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/Select';


import {CampsSearch} from './display_camps.js'
import {WorkshopSearch} from './display_workshop.js'

import {ActivitiesSearch} from './display_activities.js'

export const useStyles = makeStyles((theme) => ({
	formControl: {
	  margin: theme.spacing(1),
	  minWidth: 120,
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
}));

export function FamioSearch(props) {
	const [searchViewEventsType, setSearchViewEventsType] = useState("camps")	
	const {searchBar, organizerId} = props

	const handleClickStageView = (event) => {
		setSearchViewEventsType("camps")
	}
	const handleClickActivityView = (event) => {
		setSearchViewEventsType("activities")
	}
	const handleClickWorkshopView = (event) => {
		setSearchViewEventsType("workshop")
	}
	let display = ""
	if (searchViewEventsType === "workshop") {
		display = <WorkshopSearch organizerId = {organizerId} searchBar = "yes" />;
	} else if (searchViewEventsType === "activities") {
		display = <ActivitiesSearch organizerId = {organizerId} searchBar = "yes" />;
	} else {
		display = <CampsSearch organizerId = {organizerId} searchBar = "yes"/>;
	}
	
	return <React.Fragment>
		<div className="row">	
			<div className="col">
				<button onClick={handleClickStageView} className='btn btn-outline-primary'>Stages</button>
				<button onClick={handleClickWorkshopView} className='btn btn-outline-primary'>Ateliers</button>
				<button onClick={handleClickActivityView} className='btn btn-outline-primary'>Activités</button>
			</div>
		</div>
		<div>
			{ display }
		</div>
	</React.Fragment>
}