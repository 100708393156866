import React, {useEffect, useState}  from 'react';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Dropdown';
import {Row, Col} from 'react-bootstrap';

import {PageTitle} from './components_page'
import {
	apiCampDetail, 
	apiCampsList, 
	apiCampsListAll, 
	apiCampDetailEventByLocation, 
	apiCampDetailEventByPeriod,
	apiCampDetailEventByAllPeriod,
	apiEventsAvailability,
} from './lookup'

import {getAbsoluteURL} from '../lookup'
import {DisplayEventAvailability} from './detail_camp'
import {DisplayPeriodLocationNameDetail} from './components_page'

export function CampPage(props) {
	const [campDetail, setCampDetail] = useState([])
	const [organizer, setOrganizer] = useState([])
	
	const [pendingAPIcall, setPendingAPIcall] = useState(false)

	const {campId} = props

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log("*** Camp API reponse receive" + JSON.stringify(response))
			}
			setCampDetail(response)
			setOrganizer(response.organizer)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}

	function makeAPIcall() {
		setPendingAPIcall(true)
		apiCampDetail(campId, handleViewResponse)
	}

	useEffect(() => {
		makeAPIcall()
	}, [])


	
	return <React.Fragment>
		{pendingAPIcall ? (
								<div>Loading...</div>
							) : (
								<div>
									<CampEventsBar
										campId ={ campId }
									/>
								</div>
								)
							}

	</React.Fragment>
}

export function CampEventsBar(props) {
	const [viewType, setViewType] = useState("period")
	const [eventsDisplay, setEventsDisplay] = useState([])
	const [numberEvent, setNumberEvent] = useState([])
	const [pendingAPIcall, setPendingAPIcall] = useState(false)

	const {campId} = props

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log("*** Camp Events Bar API reponse receive" + JSON.stringify(response))
			}
			setEventsDisplay(response)
			setNumberEvent(response.length)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}

	function makeAPIcall() {
		if (viewType=== "period") {
			setPendingAPIcall(true)
			apiCampDetailEventByPeriod(campId, handleViewResponse)
		} else if (viewType=== "location") {
			setPendingAPIcall(true)
			apiCampDetailEventByLocation(campId, handleViewResponse)
		}
	}

	useEffect(() => {
		makeAPIcall()
	}, [])

	const handleClickPeriodView = (event) => {
		setViewType("period")
		apiCampDetailEventByPeriod(campId, handleViewResponse)
		setPendingAPIcall(true)
		console.log("*** API CALL PERIOD")
	}
	const handleClickLocationView = (event) => {
		setPendingAPIcall(true)
		setViewType("location")
		apiCampDetailEventByLocation(campId, handleViewResponse)
		console.log("*** API CALL LOCATION")
	}

	return <React.Fragment>
		<Row className="bg-light">
			<Col>
				<Dropdown>
					<Dropdown.Toggle variant="light btn-sm" id="dropdown-basic">
						{viewType=="period" ? (
							<div><i className={`fa fa-2x fa-calendar mr-2`}/>Par période</div>
						): (
							<div><i className={`fa fa-2x fa-map-marker mr-2`}/>Par lieu</div>
						)
						}
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item onClick={handleClickPeriodView}><i className={`fa fa-2x fa-calendar mr-2`}/>Par période</Dropdown.Item>
						<Dropdown.Item onClick={handleClickLocationView}><i className={`fa fa-2x fa-map-marker mr-2`}/>Par lieu</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Col>
			<Col>
				{pendingAPIcall ? (<div>Loading...</div>) : (
				<div>{numberEvent} {viewType=="period" ? ("période(s)"): ("lieu(x)")} disponible pour ce stage.</div>
				)}
			</Col>
		</Row>
		<Row>
			
				{pendingAPIcall ? (
								<div>Loading...</div>
							) : (
								eventsDisplay.map((item, index) => {
									return <Col className="col-auto" key={`${index}-${item.id}`}>
											<DisplayEventGenericBar
												obj = {item}
												displayType = {viewType}
												key={`${index}-${item.id}`}
											/>
									</Col>
								})
							)
							}
			
		</Row>
	</React.Fragment>
}


function DisplayEventGenericBar(props) {
	const {obj, displayType, noTitle } = props
	let events = ""
	let icon_main_filter = ""
	let icon_sub_filter = ""

	const isPeriod = (displayType === "period" ? true : false)
	const showTitle = (noTitle === "true" ? false : true)

	if (displayType === "period") {
		events = obj.period_events
		icon_main_filter = "calendar"
		icon_sub_filter = "map-marker"
	} else {
		events = obj.location_events
		icon_main_filter = "map-marker"
		icon_sub_filter = "calendar"
	}

	return <React.Fragment>
			<Card style={{ width: '18rem' }}>
				<Card.Body>
					<Card.Header className="bg-primary text-white">
						{showTitle ? ( 
							<div><i className={`fa fa-${icon_main_filter} mr-2`}/>
								{obj.name} <DisplayPeriodLocationNameDetail obj={obj} isPeriod={isPeriod} />
							</div>
						) : (<div></div>)}
					</Card.Header>


				{events.map((item, index) => {
					return <div key={`${index}-${item.id}`}>
								<Card.Text className="mb-2 text-muted">
												<i className={`fa fa-${icon_sub_filter} mr-2`}/>
												{isPeriod ? (
													item.location.name
												): (
													item.period.name
												)
												}
												<DisplayPeriodLocationNameDetail obj={item.period} isPeriod={!isPeriod} />
											<DisplayEventAvailability 
											eventId={item.id} 
											displayType="detail"
											displayButton="true"
											/>
								</Card.Text>
							</div>
					})}
			</Card.Body>
		</Card>
		</React.Fragment>
}

/**

										<Button className="btn-sm btn-primary" href={getAbsoluteURL(`family/events/${item.id}/enroll`)}>
											Inscription
										</Button>

									<PageTitle
										title= { campDetail.title }
										subtitle = { organizer.name }
										button_link = "#"
										button_text = "Inscription"
									/>
									<CampEventsBar
										campId ={ campId }
									/>
									<Image src="{{ campDetail.picture }}" />
									<p>
										{ campDetail.description }
										{ campDetail.picture }
									</p>

 */