import React, {useEffect, useState}  from 'react';
import {Badge, Card, Button, ButtonGroup, Row, Col} from 'react-bootstrap';

import {
	apiCampDetail, 
	apiCampsList, 
	apiCampsListAll, 
	apiCampDetailEventByLocation, 
	apiCampDetailEventByPeriod,
	apiCampDetailEventByAllPeriod,
	apiEventsAvailability,
} from './lookup'
import {getAbsoluteURL} from '../lookup'
import {DisplayPeriodLocationNameDetail, DisplayRatingStar} from './components_page'

export function DisplayEventAvailability(props) {
	/** Display the events availabilty takes eventId and displayType as propos
	 * 		For displayType:
	 * 			"overall": show overall status as badge icon.
	 * 			"detail": show per morning, afternoon and week status
	 * 
	 * 		For displayButton:
	 * 			"yes": display the subscription and the detail button
	 * 			"no": hide the above buttons
	 */
	const [viewAvailability, setViewAvailability] = useState([])
	const [badgeVariantOverallAvailability, setBadgeVariantOverallAvailability] = useState([])
	const [pendingAPIcall, setPendingAPIcall] = useState(false)
	const [campId,setCampId] = useState([])
	const {eventId, displayType, displayButton} = props

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			//setViewAvailability(response)
			setViewAvailability(response.status)
			setCampId(response.camp_id)
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log("*** Availabiliity API response" + JSON.stringify(response))
			}
			setBadgeVariantOverallAvailability("btn-sm btn-" + response.status.overall_color)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}

	function makeAPIcall() {
		setPendingAPIcall(true)
		apiEventsAvailability(eventId, handleViewResponse)
	}

	useEffect(() => {
		makeAPIcall()
	}, [])
	//{viewOverallAvailability.overall_text}
	return <React.Fragment>
			{pendingAPIcall ? ( <div>Availability Loading...</div>):
						(<React.Fragment>
								{displayType === "detail" ? (
								<div>
									<Row className="text-left content-justify-left">
										<Col className="col-auto text-left">
											{viewAvailability.WEEK_DAY ? (
												<Badge className="mr-1" variant={viewAvailability.WEEK_DAY == 'available' ? ('success') : ('warning')}>
													{viewAvailability.WEEK_DAY_TEXT}<br/>
													{viewAvailability.WEEK_DAY_PRICE} €
												</Badge>
											) : (<div></div>) }
											{viewAvailability.WEEK_MORNING ? (
													<Badge className="mr-1" variant={viewAvailability.WEEK_MORNING == 'available' ? ('success') : ('warning')}>
														{viewAvailability.WEEK_MORNING_TEXT}<br/>{viewAvailability.WEEK_MORNING_PRICE} €
													</Badge>
												) : (<div></div>) }
											{viewAvailability.WEEK_AFTERNOON ? (
													<Badge className="mr-1" variant={viewAvailability.WEEK_AFTERNOON == 'available' ? ('success') : ('warning')}>
														{viewAvailability.WEEK_AFTERNOON_TEXT}<br/>{viewAvailability.WEEK_AFTERNOON_PRICE} €
													</Badge>
												) : (<div></div>) }
											{viewAvailability.CUSTOM ? (
													<Badge className="mr-1" variant={viewAvailability.CUSTOM == 'available' ? ('success') : ('warning')}>
														{viewAvailability.CUSTOM_TEXT}
													</Badge>
												) : (<div></div>) }
										</Col>
									</Row>
									{displayButton ? (
											<Row>
											<Col>
											<ButtonGroup className="d-flex">
												<Button 
													type="button"
													variant="outline-info"
													size="sm"
													alignSelf= 'stretch'
													data-toggle={viewAvailability.overall_text} 
													data-placement="top" 
													title={viewAvailability.overall_text}
													href={getAbsoluteURL(`family/events/${eventId}/enroll/?next=/camps/${campId}/detail/`)}>
													<i class="fas fa-user-plus"></i> Inscription
												</Button>
												<Button 
													type="button"
													variant="outline-secondary"
													size="sm"
													alignSelf= 'stretch'
													data-toggle={viewAvailability.overall_text} 
													data-placement="top" 
													title={viewAvailability.overall_text}
													href={getAbsoluteURL(`events/${eventId}/detail/?next=/camps/${campId}/detail/`)}>
													<i class="fas fa-info-circle"></i> Plus d'informations
												</Button>
												</ButtonGroup>
											</Col>
										</Row>
									)
									:(<div></div>)}

								</div>
							) : (
								<Badge pill variant={viewAvailability.overall_color} className="mx-2">
									<i className={viewAvailability.overall_color == 'success' ? ('fa fa-check-circle') : ('fa fa-minus-circle')}/>
								</Badge>
							)
							}
						</React.Fragment>
						)
					}
		</React.Fragment>

/**
 * 								<Badge pill variant={viewAvailability.overall_color}>
									<i className={viewAvailability.overall == 'success' ? ('fa fa-check-circle mr-2') : ('fa fa-minus-circle mr-2')}/>
								</Badge>
 */
}

export function DisplayEventGeneric(props) {
	const {obj, displayType, noTitle, eventType } = props
	let events = ""
	let icon_main_filter = ""
	let icon_sub_filter = ""

	const isPeriod = (displayType === "period" ? true : false)
	const showTitle = (noTitle === "true" ? false : true)

	if (displayType === "period") {
		events = obj.period_events
		icon_main_filter = "calendar"
		icon_sub_filter = "map-marker"
	} else {
		events = obj.location_events
		icon_main_filter = "map-marker"
		icon_sub_filter = "calendar"
	}


	return <React.Fragment>
				{showTitle ? ( 
					<div>
						<div>
							<i className={`fa fa-${icon_main_filter} mr-2`}/>
							<small>
								{obj.name} <DisplayPeriodLocationNameDetail obj={obj} isPeriod={isPeriod} />
							</small>
						</div>
					</div>
				) : (<div></div>)}
				{events.map((item, index) => {
					return <div className="col" key={`${index}-${item.id}`}>
								<small>
									<a href={getAbsoluteURL(`events/${item.id}/detail`)}>
										<div>
											<div className="row">
												<div class="col-auto">
													<i className={`fa fa-${icon_sub_filter} mr-2`}/>
														{isPeriod ? (
															item.location.name
														): (
															item.period.name 
															)
														}
													<DisplayPeriodLocationNameDetail obj={item.period} isPeriod={!isPeriod} />
												</div>
												<div className="col">
													<DisplayEventAvailability 
														eventId={item.id}
														displayType="detail"
													/>
												</div>
											</div>


										</div>
									</a>
								</small>
							</div>
					})}
		</React.Fragment>
}


export function CampDetailEventCard(props) {
	const [viewType, setViewType] = useState("location")
	const [eventsDisplay, setEventsDisplay] = useState([])
	const [pendingAPIcall, setPendingAPIcall] = useState(false)

	const {campId} = props

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log("*** Camp Detail Event Card API reponse receive" + JSON.stringify(response))
			}
			setEventsDisplay(response)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}

	function makeAPIcall() {
		if (viewType=== "period") {
			setPendingAPIcall(true)
			apiCampDetailEventByPeriod(campId, handleViewResponse)
		} else if (viewType=== "location") {
			setPendingAPIcall(true)
			apiCampDetailEventByLocation(campId, handleViewResponse)
		}
	}

	useEffect(() => {
		makeAPIcall()
	}, [])

	const handleClickPeriodView = (event) => {
		setViewType("period")
		apiCampDetailEventByPeriod(campId, handleViewResponse)
		setPendingAPIcall(true)
		console.log("*** API CALL PERIOD")
	}
	const handleClickLocationView = (event) => {
		setPendingAPIcall(true)
		setViewType("location")
		apiCampDetailEventByLocation(campId, handleViewResponse)
		console.log("*** API CALL LOCATION")
	}

	return <React.Fragment>
		<div className="row">	
			<div className="col">
				<button onClick={handleClickPeriodView} className='btn btn-sm btn-outline-info'>Période</button>
				<button onClick={handleClickLocationView} className='btn btn-sm btn-outline-info'>Lieux</button>
			</div>
		</div>
		{pendingAPIcall ? (
								<div>Loading...</div>
							) : (
								eventsDisplay.map((item, index) => {
									return <div className="col" key={`${index}-${item.id}`}>
											<DisplayEventGeneric
												obj = {item}
												displayType = {viewType}
											/>
									</div>
								})
							)
							}

	</React.Fragment>
}

export function CampDetailInformation(props) {
	const {camp, viewType} = props
	const categories = camp.categories

	return <React.Fragment>
		{viewType === 'table' ? 
		(<div>
				<h5 className="card-title text-info">
					<a href={getAbsoluteURL(`camps/${camp.id}/detail`)} className="text-muted">{camp.title} </a>
					(<small>{camp.start_age} - {camp.end_age} ans</small>)
					<p className="text-muted small">
						<a href={getAbsoluteURL(`${camp.organizer.name_for_url}`)} className="text-muted">{camp.organizer.name}</a>
					</p>
				</h5>
			</div>)
		: (
			<div>
				<Card.Title><a className="card-title text-info" href={getAbsoluteURL(`camps/${camp.id}/detail`)}>{camp.title}</a>
				<br/>
				<a href={getAbsoluteURL(`${camp.organizer.name_for_url}`)} className="text-muted">{camp.organizer.name}</a>
				<small>
				<DisplayRatingStar 
					rating={camp.organizer.rating_global} />
				</small>
				</Card.Title>
				<Card.Text>

					{categories.map((item, index) => {
						return <Badge pill variant="primary" key={`${index}-${item.id}`}>{item.name_fr}</Badge>
						})}
						<br/>
						<i className="fa fa-birthday-cake mr-2"></i><small>{camp.start_age} - {camp.end_age} ans</small>
				</Card.Text>
			</div>
		)
		}
	
	</React.Fragment>
}

export function CampEventDetailInformation(props) {
	const {event} = props

	return <React.Fragment>
			<Row>
				<Col md="auto">
					{event.stage.picture ? (
					<a href={getAbsoluteURL(`camps/${event.stage.id}/detail`)}>
						<Card.Img variant="top" src={event.stage.picture} style={{ width: '10rem' }}/>
					</a>
				) : (<div></div>)}
				</Col>
				<Col md="auto">
					<CampDetailInformation
					camp={event.stage}
					viewType="card"
					key={`detail-card-${event.stage.id}`}
					/>
				{event.period.name} <DisplayPeriodLocationNameDetail obj={event.period} isPeriod={"True"} />
				</Col>
			</Row>
	</React.Fragment>
}


// 						return <span className="badge badge-pill badge-primary" key={`${index}-${item.id}`}>{item.name}</span>
export function CampDetailCard(props) {
	const {camp} = props
//	const className = props.className ? props.className : 'col-10 mx-auto col-md-6'

	return <Card className="card shadow mb-4" style={{ width: '22rem' }}>
				{camp.picture ? (
						<a href={getAbsoluteURL(`camps/${camp.id}/detail`)}>
							<Card.Img variant="top" src={camp.picture} />
						</a>
				) : (<div></div>)}
				<div className="m-2" >
				<CampDetailInformation
					camp={camp}
					viewType="card"
					key={`detail-card-${camp.id}`}
				/>
				<CampDetailEventCard
					campId={camp.id}
					/>
				</div>
		</Card>
  }


export function CampsDetailTable(props) {
	const [campEventsTableDisplay, setCampEventsTableDisplay] = useState([])

	const [pendingAPIcall, setPendingAPIcall] = useState(false)

	const {camp} = props

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log("*** Camp Detail Event table API reponse receive" + JSON.stringify(response))
			}
			setCampEventsTableDisplay(response)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}

	useEffect(() => {
		apiCampDetailEventByAllPeriod(camp.id, handleViewResponse)
		setPendingAPIcall(true)
	}, [])

	return <React.Fragment>
				{pendingAPIcall ? (
					<div>Loading...</div>
				): (
					<tr>
						<td>
						<CampDetailInformation
									camp={camp}
									viewType = "table"
								/>
						</td>
						{campEventsTableDisplay.map((item, index) => {
							return <td>
								<DisplayEventGeneric
									obj = {item}
									displayType = "period"
									noTitle = "true"
									key={`${index}-${item.id}`} 
								/>
							</td>
						})}
					</tr>
				)}
		</React.Fragment>


}






