import React, {useEffect, useState}  from 'react';
import {Badge, Card, Button, ButtonGroup, Row, Col} from 'react-bootstrap';

import {
	apiActivityEventsAvailability,
	apiActivityDetailEventByLocation,
	apiActivityDetailEventByDay,
} from './lookup'

import {
	DisplayEventGeneric,
	DisplayEventAvailability
} from './detail_camp'

import {DisplayPeriodLocationNameDetail} from './components_page'

import {getAbsoluteURL} from '../lookup'

export function DisplayActivityEventAvailability(props) {
	/** Display the events availabilty takes eventId and displayType as propos
	 * 		For displayType:
	 * 			"overall": show overall status as badge icon.
	 * 			"detail": show per morning, afternoon and week status
	 * 
	 * 		For displayButton:
	 * 			"yes": display the subscription and the detail button
	 * 			"no": hide the above buttons
	 */
	const [viewAvailability, setViewAvailability] = useState([])
	const [badgeVariantOverallAvailability, setBadgeVariantOverallAvailability] = useState([])
	const [pendingAPIcall, setPendingAPIcall] = useState(false)
	const [activityId,setActivityId] = useState([])
	const {eventId, displayType, text, displayButton} = props

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			//setViewAvailability(response)
			setViewAvailability(response.status)
			setActivityId(response.activity_id)
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log("*** Availabiliity API response" + JSON.stringify(response))
			}
			setBadgeVariantOverallAvailability("btn-sm btn-" + response.status.overall_color)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}

	function makeAPIcall() {
		setPendingAPIcall(true)
		apiActivityEventsAvailability(eventId, handleViewResponse)
	}

	useEffect(() => {
		makeAPIcall()
	}, [])
	//{viewOverallAvailability.overall_text}
	//<i className={viewAvailability.overall_color == 'success' ? ('fa fa-check-circle') : ('fa fa-minus-circle')}/>
	return <React.Fragment>
			{pendingAPIcall ? ( <div>Availability Loading...</div>):
						(<React.Fragment>
								{displayType === "detail" ? (
								<div>
								</div>
							) : (<a href={getAbsoluteURL(`family/activity/event/${eventId}/enroll`)} className="text-muted">
								<Badge pill variant={viewAvailability.overall_color} className="mx-2">
										{text}
								</Badge>
								</a>
							)
							}
						</React.Fragment>
						)
					}
		</React.Fragment>
}

export function ActivityDetailCard(props) {
	const {activity} = props

	return <Card className="card shadow mb-4" style={{ width: '22rem' }}>
				{activity.picture ? (
						<a href={getAbsoluteURL(`activity/${activity.id}/detail`)}>
							<Card.Img variant="top" src={activity.picture} />
						</a>
				) : (<div></div>)}
				<div className="m-2" >
				<ActivityDetailInformation
					activity={activity}
					viewType="card"
					key={`detail-card-${activity.id}`}
				/>
				<ActivityDetailEventCard
					activityId={activity.id}
				/>
				</div>
		</Card>
}

export function ActivityDetailInformation(props) {
	const {activity, viewType} = props
	const categories = activity.categories

	//console.log("DETIAL INFO " + JSON.stringify(activity))

	return <React.Fragment>
		{viewType === 'table' ? 
		(<div>
				<h5 className="card-title text-info">
					<a href={getAbsoluteURL(`activity/${activity.id}/detail`)} className="text-muted">{activity.title} </a>
					<br/>(<small>{activity.start_age} - {activity.end_age} ans</small>)
					<p className="text-muted small">
						<a href={getAbsoluteURL(`${activity.organizer.name_for_url}`)} className="text-muted">{activity.organizer.name}</a>
					</p>
				</h5>
			</div>)
		: (
			<div>
				<Card.Title><a className="card-title text-info" href={getAbsoluteURL(`activity/${activity.id}/detail`)}>{activity.title}</a>
				<br/>
				<a href={getAbsoluteURL(`${activity.organizer.name_for_url}`)} className="text-muted">{activity.organizer.name}</a>
						
				</Card.Title>
				<Card.Text>
					{categories.map((item, index) => {
						return <Badge pill variant="primary" key={`${index}-${item.id}`}>{item.name_fr}</Badge>
						})}
						<br/>
						<i className="fa fa-birthday-cake mr-2"></i><small>{activity.start_age} - {activity.end_age} ans</small>
						<br/>
						<i className="fa fa-euro-sign mr-2"></i><small dangerouslySetInnerHTML={{__html: activity.schedule_price.show_schedule_html}} ></small>
				
				</Card.Text>
			</div>
		)
		}
	
	</React.Fragment>
}


export function ActivityDetailInformationDisplaySchedulePrice(props) {
	const {obj} = props

	return <React.Fragment>
		<div>
			{obj.map((item, index) => {
					return <small><i className="fa fa-euro-sign mr-2"></i>{item}</small>
				})}
		</div>
	</React.Fragment>
}

export function ActivityDetailEventCard(props) {
	const [viewType, setViewType] = useState("location")
	const [eventsDisplay, setEventsDisplay] = useState([])
	const [pendingAPIcall, setPendingAPIcall] = useState(false)

	const {activityId} = props

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log("*** Activity Detail Event Card API reponse receive" + JSON.stringify(response))
			}
			setEventsDisplay(response)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}

	function makeAPIcall() {
		apiActivityDetailEventByLocation(activityId, handleViewResponse)
	}

	useEffect(() => {
		makeAPIcall()
	}, [])

	const handleClickLocationView = (event) => {
		setPendingAPIcall(true)
		setViewType("location")
		apiActivityDetailEventByLocation(activityId, handleViewResponse)
		console.log("*** API CALL LOCATION")
	}

	return <React.Fragment>
		{pendingAPIcall ? (
								<div>Loading...</div>
							) : (
								eventsDisplay.map((item, index) => {
									return <div className="col" key={`${index}-${item.id}`}>
											<ActivityDisplayEventGeneric
												obj = {item}
												displayType = {viewType}
											/>
									</div>
								})
							)
							}

	</React.Fragment>
}


export function ActivityDisplayEventGeneric(props) {
	const {obj, displayType, noTitle, eventType } = props
	let events = ""
	let icon_main_filter = ""
	let icon_sub_filter = ""

	const isPeriod = (displayType === "period" ? true : false)
	const showTitle = (noTitle === "true" ? false : true)

	if (displayType === "period") {
		events = obj.period_events
		icon_main_filter = "calendar"
		icon_sub_filter = "map-marker"
	} else {
		events = obj.location_events
		icon_main_filter = "map-marker"
		icon_sub_filter = "calendar"
	}


	return <React.Fragment>
				{showTitle ? ( 
					<div>
						<div>
							<i className={`fa fa-${icon_main_filter} mr-2`}/>
							<small>
								{obj.name} <DisplayPeriodLocationNameDetail obj={obj} isPeriod={isPeriod} />
							</small>
						</div>
					</div>
				) : (<div></div>)}
				{events.map((item, index) => {
					return <div className="col" key={`${index}-${item.id}`}>
									
									<div>
										<div className="row">
											<div className="col">
												<DisplayActivityEventAvailability 
													eventId={item.id}
													displayType="overall"
													text={`${item.schedule_day_hour_level_text}`}
												/>
											</div>
										</div>
									</div>
							</div>
					})}
		</React.Fragment>
}

export function ActivityDetailTable(props) {
	const [activityEventsTableDisplay, setActivityEventsTableDisplay] = useState([])
	const [activityEventsMonday, setActivityEventsMonday] = useState([])
	const [activityEventsTuesday, setActivityEventsTuesday] = useState([])
	const [activityEventsWednesday, setActivityEventsWednesday] = useState([])
	const [activityEventsThursday, setActivityEventsThursday] = useState([])
	const [activityEventsFriday, setActivityEventsFriday] = useState([])
	const [activityEventsSaturday, setActivityEventsSaturday] = useState([])
	const [activityEventsSunday, setActivityEventsSunday] = useState([])

	const [pendingAPIcall, setPendingAPIcall] = useState(false)

	const {activity} = props

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log("*** Activity Detail Event table API reponse receive" + JSON.stringify(response))
			}
			setActivityEventsTableDisplay(response.events_per_day)
			setActivityEventsMonday(response.events_per_day.MONDAY)
			setActivityEventsTuesday(response.events_per_day.TUESDAY)
			setActivityEventsWednesday(response.events_per_day.WEDNESDAY)
			setActivityEventsThursday(response.events_per_day.THURSDAY)
			setActivityEventsFriday(response.events_per_day.FRIDAY)
			setActivityEventsSaturday(response.events_per_day.SATURDAY)
			setActivityEventsSunday(response.events_per_day.SUNDAY)

			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}

	useEffect(() => {
		apiActivityDetailEventByDay(activity.id, handleViewResponse)
		setPendingAPIcall(true)
	}, [])

	return <React.Fragment>
				{pendingAPIcall ? (
					<div>Loading...</div>
				): (
					<tr>
						<td>
						<ActivityDetailInformation
									activity={activity}
									viewType = "table"
									key={`detail-card-${activity.id}`}
								/>
						</td>
						<td>
						{activityEventsMonday.map((item, index) => {
							return <DisplayActivityEvent
									obj = {item}
									displayType = "location"
									noTitle = "false"
									key={`${index}-${item.id}`} 
								/>
						})}
						</td>
						<td>
						{activityEventsTuesday.map((item, index) => {
							return <DisplayActivityEvent
									obj = {item}
									displayType = "location"
									noTitle = "false"
									key={`${index}-${item.id}`} 
								/>
						})}
						</td>
						<td>
						{activityEventsWednesday.map((item, index) => {
							return <DisplayActivityEvent
									obj = {item}
									displayType = "location"
									noTitle = "false"
									key={`${index}-${item.id}`} 
								/>
						})}
						</td>
						<td>
						{activityEventsThursday.map((item, index) => {
							return <DisplayActivityEvent
									obj = {item}
									displayType = "location"
									noTitle = "false"
									key={`${index}-${item.id}`} 
								/>
						})}
						</td>
						<td>
						{activityEventsFriday.map((item, index) => {
							return <DisplayActivityEvent
									obj = {item}
									displayType = "location"
									noTitle = "false"
									key={`${index}-${item.id}`} 
								/>
						})}
						</td>
						<td>
						{activityEventsSaturday.map((item, index) => {
							return <DisplayActivityEvent
									obj = {item}
									displayType = "location"
									noTitle = "false"
									key={`${index}-${item.id}`} 
								/>
						})}
						</td>
						<td>
						{activityEventsSunday.map((item, index) => {
							return <DisplayActivityEvent
									obj = {item}
									displayType = "location"
									noTitle = "false"
									key={`${index}-${item.id}`} 
								/>
						})}
						</td>
					</tr>
				)}
		</React.Fragment>
}



export function DisplayActivityEvent(props) {
	const {obj, displayType, noTitle, eventType } = props
	let events = ""
	let icon_main_filter = ""
	let icon_sub_filter = ""

	const isPeriod = (displayType === "period" ? true : false)
	const showTitle = (noTitle === "true" ? false : true)

	if (displayType === "period") {
		events = obj.period_events
		icon_main_filter = "calendar"
		icon_sub_filter = "map-marker"
	} else {
		events = obj.location_events
		icon_main_filter = "map-marker"
		icon_sub_filter = "calendar"
	}
	

	return <React.Fragment>
				{showTitle ? ( 
					<div>
						<div>
							<i className={`fa fa-${icon_main_filter} mr-2`}/>
							<small>
								{obj.name} <DisplayPeriodLocationNameDetail obj={obj} isPeriod={isPeriod} />
							</small>
						</div>
					</div>
				) : (<div></div>)}
				{events.map((item, index) => {
					return <a href={getAbsoluteURL(`events/${item.id}/detail`)} key={`${index}-${item.id}`}>
									<DisplayActivityEventAvailability 
										eventId={item.id}
										displayType="overall"
										text={`${item.schedule_hour_level_text}`}
									/>
									<br/>
								</a>
					})}
		</React.Fragment>
}

//export function DisplayActivityEvent(props) {
//	const {obj} = props
//
//	console.log("*** DEBUG ACTIVITY OBJ" + obj)
//			
//
//	return <React.Fragment>
//				{obj.map((item, index) => {
//					return <div className="col" key={`${index}-${item.id}`}>
//								<small>
//									<a href={getAbsoluteURL(`activity/events/${item.id}/detail`)}>
//										<div>
//											<div className="row">
//												<div class="col-auto">
//													<i className={`fa fa-map-marker mr-2`}/>
//														{item.name}
//												</div>
//												<div className="col">
//													
//												</div>
//											</div>
//
//
//										</div>
//									</a>
//								</small>
//							</div>
//					})}
//		</React.Fragment>
//}