import {backendLookup, myBackendRequest} from '../lookup'

export function apiCampDetail(campId, callback) {
    backendLookup("GET", `/camps/${campId}/detail`, callback)
}

export function apiCampDetailEventByLocation(campId, callback) {
    backendLookup("GET", `/camps/${campId}/detail/events_by_location`, callback)
}

export function apiCampDetailEventByPeriod(campId, callback) {
    backendLookup("GET", `/camps/${campId}/detail/events_by_period`, callback)
}
export function apiCampDetailEventByAllPeriod(campId, callback) {
    backendLookup("GET", `/camps/${campId}/detail/events_by_period?all_upcoming_periods=1`, callback)
}

export function apiEventsList(callback) {
    let endpoint =  "/events/list/"
    backendLookup("GET", endpoint, callback)
}

export function apiEventsAvailability(eventId, callback) {
	backendLookup("GET", `/events/${eventId}/availability`, callback)
}

export function apiActivityEventsAvailability(eventId, callback) {
	backendLookup("GET", `/activity/events/${eventId}/availability`, callback)
}

export function apiEventsDetail(eventId, callback) {
	backendLookup("GET", `/events/${eventId}/detail`, callback)
}

export function apiScheduleDetail(scheduleId, callback) {
	backendLookup("GET", `/schedule/${scheduleId}/detail`, callback)
}

export function apiCampsList(callback, nextUrl) {
	let endpoint =  "/camps/list/"
	backendLookup("GET", endpoint, callback, nextUrl)
}

export function apiCampsListFilter(callback, filter, nextUrl) {
	let endpoint =  "/camps/list/" + filter
	backendLookup("GET", endpoint, callback, nextUrl)
}

export function apiWorkshopListFilter(callback, filter, nextUrl) {
	let endpoint =  "/workshop/list/" + filter
	backendLookup("GET", endpoint, callback, nextUrl)
}


export function apiCampsListAll(callback) {
    let endpoint =  "/camps/list/all"
    backendLookup("GET", endpoint, callback)
}

export function apiCampsPerOrganizer(organizerId, callback) {
	backendLookup("GET", `/camps/list/?organizer_id=${organizerId}`, callback)
}

export function apiPeriodsUpcoming(callback) {
    let endpoint =  "/periods/upcoming/"
    backendLookup("GET", endpoint, callback)
}

export function apiSearchOptions(callback, filter) {
    let endpoint =  "/search/options/" + filter
    backendLookup("GET", endpoint, callback)
}

export function apiSearchOptionsWorkshop(callback, filter) {
	// 	organizer_id = request.GET.get('organizer_id', '')
    let endpoint =  "/workshop/search/options/" + filter
    backendLookup("GET", endpoint, callback)
}

export function apiSearchOptionsActivities(callback, filter) {
	// 	organizer_id = request.GET.get('organizer_id', '')
    let endpoint =  "/activity/search/options/" + filter
    backendLookup("GET", endpoint, callback)
}



// ACTIVITIES

export function apiActivitiesListFilter(callback, filter, nextUrl) {
	let endpoint =  "/activity/list/" + filter
	backendLookup("GET", endpoint, callback, nextUrl)
}

export function apiActivityDetailEventByLocation(campId, callback) {
    backendLookup("GET", `/activity/${campId}/detail/events_by_location`, callback)
}

export function apiActivityDetailEventByDay(activityId, callback) {
    backendLookup("GET", `/activity/${activityId}/detail/events_by_day`, callback)
}


// FEEDBACK

export function apiFeedbackOrganizer(organizerId, callback) {
	backendLookup("GET", `/organizer/${organizerId}/feedback`, callback)
}

// ORGANIZER SUBSCRIPTIONS

export function apiEventOrganizer(callback) {
	backendLookup("GET", `/organizer/events/`, callback)
}

export function apiSubscriptionEventOrganizer(eventId, callback) {
	backendLookup("GET", `/organizer/events/${eventId}/subscription`, callback)
}

// QUICK SUBSCRIPTION

export function apiQuickSubscriptionEvent(eventId, data, setResponse, callback) {
	myBackendRequest("POST", `/events/${eventId}/quick_subscription/`, setResponse, "", data, callback)
}