import React, {useEffect, useState}  from 'react'
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge'
import Form from 'react-bootstrap/Form'

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/Select';

import {
	apiEventOrganizer,
	apiSubscriptionEventOrganizer,
} from './lookup'

export function DisplaySubscriptions(props) {
	const [events, setEvents] = useState()
	const [pendingAPIcall, setPendingAPIcall] = useState(true)

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			setEvents(response)
			setPendingAPIcall(false)
		} else {
			if (process.env.REACT_APP_DEBUG === 'true') {
				console.log("oranizer object " + JSON.stringify(events))
			}
			alert("There was an error")
		}
	}
	useEffect(() => {
		apiEventOrganizer(handleViewResponse)
		setPendingAPIcall(true)
	}, [])
	return <React.Fragment>
				<h3>
					Subscriptions
				</h3>
				{pendingAPIcall ? (<div>Loading...</div>)
				:( 
					<div>
						{events.map((item, index) => {
							return item[index]
						})}
					</div>
				)
				}
			</React.Fragment>
}

export function ListSubscriptions(props) {
	const [subscriptions, setSubscriptions] = useState()
	const [pendingAPIcall, setPendingAPIcall] = useState(true)

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			setSubscriptions(response)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}
	useEffect(() => {
		apiSubscriptionEventOrganizer(handleViewResponse)
		setPendingAPIcall(true)
	}, [])
	return <React.Fragment>
				{pendingAPIcall ? (<div>Loading...</div>)
				:( 
					<div>
						{subscriptions.map((item, index) => {
							return item[index]
						})}
					</div>
				)
				}
			</React.Fragment>
}
