import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {FamioSearch, CampsSearch, WorkshopSearch, ActivitiesSearch,
	CampDetailEventCard, DisplayEventAvailability, 
	DisplayActivityEventAvailability, EnrollKid, 
	CampPage, ActivityDetailEventCard, OrganizerFeedback,DisplayRatingStar,DisplaySubscriptions,QuickSubscription,
} from './camps'

//ReactDOM.render(
//  <React.StrictMode>
//    <App />
//  </React.StrictMode>,
//  document.getElementById('root')
//);

//const appEl = document.getElementById('root')
//if (appEl) {
//    ReactDOM.render(<App />, appEl);
//}

const e = React.createElement

// Display only per type: Camp, Workshop or Activity
const campsEl = document.getElementById("famio-display-camps")
if (campsEl) {
    ReactDOM.render(
        e(CampsSearch, campsEl.dataset), campsEl);
}

const subscribeEl = document.getElementById("famio-quick-subscription")
if (subscribeEl) {
    ReactDOM.render(
        e(QuickSubscription, subscribeEl.dataset), subscribeEl);
}

const workshopEl = document.getElementById("famio-display-workshops")
if (workshopEl) {
    ReactDOM.render(
        e(WorkshopSearch, workshopEl.dataset), workshopEl);
}
const activityEl = document.getElementById("famio-display-activities")
if (activityEl) {
    ReactDOM.render(
        e(ActivitiesSearch, activityEl.dataset), activityEl);
}

// Display for all possible search (Camps, Workshop, Activity)
const famioEl = document.getElementById("famio-search")
if (famioEl) {
    ReactDOM.render(
        e(FamioSearch, famioEl.dataset), famioEl);
}

const campsEventsEl = document.getElementById("famio-detail-events-camp")
if (campsEventsEl) {
    ReactDOM.render(
        e(CampDetailEventCard, campsEventsEl.dataset), campsEventsEl);
}

const organizerCampsEl = document.getElementById("famio-organizer-camps")
if (organizerCampsEl) {
    ReactDOM.render(
        e(CampsSearch, organizerCampsEl.dataset), organizerCampsEl);
}

const eventAvailabilityEl = document.getElementById("famio-organizer-events-availability")
if (eventAvailabilityEl) {
    ReactDOM.render(
        e(DisplayEventAvailability, eventAvailabilityEl.dataset), eventAvailabilityEl);
}

const activityEventAvailabilityEl = document.getElementById("famio-organizer-activity-events-availability")
if (activityEventAvailabilityEl) {
    ReactDOM.render(
        e(DisplayActivityEventAvailability, activityEventAvailabilityEl.dataset), activityEventAvailabilityEl);
}


const enrollKidEl = document.getElementById("famio-organizer-events-enroll")
if (enrollKidEl) {
    ReactDOM.render(
        e(EnrollKid, enrollKidEl.dataset), enrollKidEl);
}

const campPageEl = document.getElementById("famio-camp-page")
if (campPageEl) {
    ReactDOM.render(
        e(CampPage, campPageEl.dataset), campPageEl);
}


const campDetailEventElements = document.querySelectorAll(".famio-detail-events-camp")

campDetailEventElements.forEach(container=> {
    ReactDOM.render(
        e(CampDetailEventCard, container.dataset), 
        container);
})

const activityDetailEventElements = document.querySelectorAll(".famio-detail-activity-events")

activityDetailEventElements.forEach(container=> {
    ReactDOM.render(
        e(ActivityDetailEventCard, container.dataset), 
        container);
})



const eventAvailabilityElAll = document.querySelectorAll(".famio-organizer-events-availability")
eventAvailabilityElAll.forEach(container=> {
    ReactDOM.render(
        e(DisplayEventAvailability, container.dataset), 
        container);
})

const activityEventAvailabilityElAll = document.querySelectorAll(".famio-organizer-activity-events-availability")
activityEventAvailabilityElAll.forEach(container=> {
    ReactDOM.render(
        e(DisplayActivityEventAvailability, container.dataset), 
        container);
})

const organizerFeedbackEl = document.getElementById("famio-display-organizer-feedback")
if (organizerFeedbackEl) {
    ReactDOM.render(
        e(OrganizerFeedback, organizerFeedbackEl.dataset), organizerFeedbackEl);
}


const feedbackStarEl = document.querySelectorAll(".famio-display-generic-star")

feedbackStarEl.forEach(container=> {
    ReactDOM.render(
        e(DisplayRatingStar, container.dataset), 
        container);
})


const organizerSubscriptionsEl = document.getElementById("famio-organizer-subscriptions")
if (organizerSubscriptionsEl) {
    ReactDOM.render(
        e(DisplaySubscriptions, organizerSubscriptionsEl.dataset), organizerSubscriptionsEl);
}


//const campsDetailEl = document.getElementById("camp-display-events")
//if (campsEl) {
//    ReactDOM.render(
//        e(CampsDisplayEvents, campsDetailEl.dataset), campsDetailEl);
//}
//

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
