import React, {useEffect, useState}  from 'react';
import {Badge, Card, Button, ButtonGroup, Row, Col} from 'react-bootstrap';

import {
	apiFeedbackOrganizer,
} from './lookup'

import {DisplayRatingStar} from './components_page'


export function OrganizerFeedback(props) {
	const [globalRating, setGlobalRating] = useState()
	const [feedbackList, setFeedbackList] = useState()
	const [nbrEvaluation, setNbrEvaluation] = useState()
	const [feedback, setFeedback] = useState()
	const [pendingAPIcall, setPendingAPIcall] = useState(true)

	// Get the period directly from the Search Bar to avoid double API call
	// for the same organizer periods.
	const {organizerId} = props

	const handleViewResponse = (response, status) =>{
		if (status === 200){
			setFeedback(response)
			setFeedbackList(response.feedbacks)
			setGlobalRating(response.global_rating)
			setNbrEvaluation(response.nbr_evaluation)
			setPendingAPIcall(false)
		} else {
			alert("There was an error")
		}
	}
	useEffect(() => {
		apiFeedbackOrganizer(organizerId, handleViewResponse)
		setPendingAPIcall(true)
	}, [])
	return <React.Fragment>
			{pendingAPIcall ? (
			<div>Loading...</div>
			): ( <div>
				<p>
				<DisplayRatingStar 
					rating={feedback.global_rating}
					nbr_evaluation={feedback.nbr_evaluation}
					nbr_evaluation_with_comments={feedback.nbr_evaluation_with_comments}/>
					</p>
					<div>
						{feedbackList.map((item, index) => {
							return <Card>
								<Card.Body>
									<p className=""><small>Créé le {item.date} par {item.author}</small><br/>
									<DisplayRatingStar 
									rating={item.rating_global}/>
									{item.comment}</p>
								</Card.Body>
								</Card>
							})}
					</div>
			</div>
		) }
	</React.Fragment>
}