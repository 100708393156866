import React, {useEffect, useState} from 'react';
//import logo from './logo.svg';
//import './App.css';
import './css/fontawesome-free/css/all.min.css'
import './famio.css';
import {CampsSearch} from './camps'


function App() {

	return (
			<div className="App">
				<header className="App-header">
					<p>
					Edit <code>src/App.js</code> and save to reload.
					</p>
					<div>
						<CampsSearch />
					</div>
					<a
					className="App-link"
					href="https://reactjs.org"
					target="_blank"
					rel="noopener noreferrer"
					>
					Learn React
					</a>
				</header>
			</div>
	);
}

export default App;
