import React, {useEffect, useState}  from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { scryRenderedComponentsWithType } from 'react-dom/test-utils';

import {getCookie} from '../lookup'

export function CSRFToken() {
	var csrftoken = getCookie('csrftoken');
	return (
		<input type="hidden" name="csrfmiddlewaretoken" value={csrftoken} />
	);
}

export function CampDescription(props) {

	const {camp} = props

	return <React.Fragment>
		{camp.title}
	</React.Fragment>
}

export function EventDescription(props) {

	const {event} = props

	console.log("Event description " + event)

	return <React.Fragment>
		<CampDescription camp={event.stage} />

	</React.Fragment>
}

export function PageTitle(props) {

	const {title, subtitle, button_link, button_text} = props

	return <React.Fragment>
		<Row>
			<Col>
				<h2 className="text-info font-weight-bold">{title}</h2>
				<h5>{subtitle}</h5>
			</Col>
			<Col>
				<Button variant="primary" href={button_link}>{button_text}</Button>
			</Col>
		</Row>
	</React.Fragment>
}

export function DisplayPeriodLocationNameDetail(props) {
	const {obj, isPeriod} = props

	var date_start_date = new Date(obj.start_date);
	var date_start = date_start_date.getDate() + '/' + (date_start_date.getMonth() + 1) + '/' + (date_start_date.getFullYear() );

	var date_end_date = new Date(obj.end_date);
	var date_end = date_end_date.getDate() + '/' + (date_end_date.getMonth() + 1) + '/' + (date_end_date.getFullYear() );

	if (isPeriod && date_start != date_end) {
		return <React.Fragment>
		({date_start} - {date_end})
		</React.Fragment>
	} else if (isPeriod && date_start == date_end) {
		return <React.Fragment>
		({date_start})
		</React.Fragment>
	} else {
		return ""
	}
}


export function DisplayRatingStar(props) {
	const {rating, nbr_evaluation, nbr_evaluation_with_comments} = props

	var start_rating = [];
	var start_rating_empty = [];
	var full_star = parseInt(rating)
	var empty_star = 5 - full_star
	for (let i = 0; i < full_star; i++)
		start_rating.push({icon: 'fas fa-star'})
	for (let i = 0; i < empty_star; i++)
		start_rating_empty.push({icon: 'far fa-star'})

	if (full_star > 0) {
		return <React.Fragment>
		<div>
		{start_rating.map((todo) => (
			<i className="text-info fas fa-star"></i>
			))}
		{start_rating_empty.map((todo) => (
			<i className="far fa-star"></i>
			))}
			{nbr_evaluation > 0 ? (
				<p>({nbr_evaluation} évaluations, {nbr_evaluation_with_comments} messages)</p>
			): (<p></p>) }
			</div> 
	</React.Fragment>
	} else {
		return ""
	}

}


export function ScheduleChoice(props) {
	const {schedule, organizer, scheduleSelected, setScheduleSelected, 
		scheduleSelectedText, setScheduleSelectedText, totalPrice, 
		setTotalPrice, paymentType, setPaymentType, formData, setFormData} = props
	//const [paimentTypeText, setPaimentTypeText] = useState()

	const handleSelect = (e) => {
		setScheduleSelected(schedule.schedule_array[e.target.name])
		//console.log(
		//	Object.entries(schedule.schedule_array[e.target.name])
		//	.map( ([key, value]) => `${key}: ${value}` )
		//  )
		setTotalPrice(schedule.schedule_array[e.target.name]["price"])
		setFormData((prevData) => ({
		...prevData,
		['schedule']: e.target.name,
		}));
		//console.log("new price " + schedule.schedule_array[e.target.name]["price"])
	};

	const handleSelectPaymentType = (e) => {
		setPaymentType(schedule.payment_method_array[e.target.name])
		//setPaymentType(e.target.name)
		setFormData((prevData) => ({
			...prevData,
			['payment_type']: e.target.name,
			}));
	};

	return <React.Fragment>
		<Row className="justify-content-md-center">
			<Col md="auto">
				<Row className="justify-content-md-center">
					{Object.keys(schedule.schedule_array).map((key, index) => ( 
						<Col md="auto" key={`${index}-${schedule.schedule_array[key]}`}>
							<Button name={key} id={index} onClick={handleSelect} className="btn btn-info">
								{schedule.schedule_array[key].text} <br />
								{schedule.schedule_array[key].price} €
							</Button>
						</Col>)
						)}
				</Row>
				<Row>
					<Col>
					<br / >Méthode de paiement : 
					</Col>
				</Row>

				<Row className="justify-content-md-center mt-2">
					{Object.keys(schedule.payment_method_array).map((key, index) => ( 
									<Col md="auto" key={`${index}-${schedule.payment_method_array[key]}`}>
										<Button name={key} id={index} onClick={handleSelectPaymentType} className="btn btn-sm btn-info">
											{schedule.payment_method_array[key].text}
										</Button>
									</Col>)
						)}
				</Row>
			</Col>
				
			<Col md="auto" className="border-left"> 
				<strong>Résumé</strong> <br/ >
				{scheduleSelected ? (
					<div>
						{scheduleSelected["text"]} : {scheduleSelected["price"]} €
					</div>): (<div></div>)} 
				<h2>Total : <strong>{totalPrice} €</strong></h2><br />
				Méthode de paiement : {paymentType.text}
			</Col>
		</Row>

	</React.Fragment>

}