//const API_BASE_URL = "http://192.168.0.13:8000/"
let API_BASE_URL = "http://localhost:8000/"
//const API_BASE_URL = "http://www.famio.be/"
//const API_BASE_URL = "https://www.famio.be/"

if (window.location.hostname == "localhost") {
	API_BASE_URL = "http://localhost:8000/"
} else if (window.location.hostname == "demo.famio.be") {
	API_BASE_URL = window.location.protocol + "//" + window.location.hostname + "/"
} else if (window.location.hostname == "famio-backup.herokuapp.com") {
	API_BASE_URL = window.location.protocol + "//" + window.location.hostname + "/"
} else {
	API_BASE_URL = "https://www.famio.be/"
}

//if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//if (process.env.REACT_APP_STAGE === 'prd') {
	//API_BASE_URL = "https://www.famio.be/"
//	API_BASE_URL = window.location.protocol + "//" + window.location.hostname + "/"
//}
if (process.env.REACT_APP_DEBUG === 'true') {
	console.log("Debug enabled (ENV VAR process.env.REACT_APP_DEBUG " + process.env.REACT_APP_DEBUG + ")")
	console.log("ENV VAR process.env.REACT_APP_STAGE " + process.env.REACT_APP_STAGE)
	console.log("ENV VAR process.env.NODE_ENV " + process.env.NODE_ENV)
	console.log("API BASE URL: " + API_BASE_URL)
}
export function getAbsoluteURL(endpoint) {
	return `${API_BASE_URL}${endpoint}`
}


export function getCookie(name) {
	var cookieValue = null;
	if (document.cookie && document.cookie !== '') {
		var cookies = document.cookie.split(';');
		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i].trim();
			// Does this cookie string begin with the name we want?
			if (cookie.substring(0, name.length + 1) === (name + '=')) {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
	}
	return cookieValue;
}

const getCookieNew = (name) => {
	const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
	return cookieValue ? cookieValue.pop() : '';
};


// full_url is an optional argument used to load next page.
export function backendLookup(method, endpoint, callback, nextUrl, data) {
	let jsonData;
	if (data){
		jsonData = JSON.stringify(data)
		console.log("API CALL with data " + jsonData)
	}
	const xhr = new XMLHttpRequest()

	if (nextUrl !== null && nextUrl !== undefined && nextUrl != "" ) {
		endpoint = nextUrl.replace(API_BASE_URL + "api", "")
	}

	const url = getAbsoluteURL(`api${endpoint}`)
	if (process.env.REACT_APP_DEBUG === 'true') {
		console.log("API CALL to " + url)
	}
	xhr.responseType = "json"
	const csrftoken = getCookie('csrftoken')
	xhr.open(method, url)
	xhr.setRequestHeader("Content-Type", "application/json")


	if (csrftoken){
		//xhr.setRequestHeader("HTTP_X_REQUESTED_WITH", "XMLHttpRequest")
		xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")
		xhr.setRequestHeader("X-CSRFToken", csrftoken)
	}
	
	xhr.onload = function() {
		callback(xhr.response, xhr.status)
	}
	xhr.onerror = function (e) {
		console.log(e)
		callback({"message": "The request was an error"}, 400)
	}
	
	xhr.send(jsonData)
}


export async function myBackendRequest(method, endpoint, setResponse, nextUrl, data, callback) {

	if (nextUrl !== null && nextUrl !== undefined && nextUrl != "" ) {
		endpoint = nextUrl.replace(API_BASE_URL + "api", "")
	}

	let jsonData;
	if (data){
		jsonData = JSON.stringify(data)
		console.log("API CALL with data " + jsonData)
	}

	const url = getAbsoluteURL(`api${endpoint}`)
	if (process.env.REACT_APP_DEBUG === 'true') {
		console.log("API CALL to " + url)
	}
	const csrftoken = getCookieNew('csrftoken');

	const response = await fetch(url, {
		method: "POST",
		headers: {
			'Content-Type': 'application/json',
			'X-CSRFToken': csrftoken,
		},
		body: jsonData,
	});
	const responseData = await response.json();
   // setData(responseData);
   	//setResponse(responseData)
	if (response.ok) {
		callback(responseData)
	} else {
		callback({"message": "Il y a eu un problème"})
	}
	//callback(responseData, responseData.status)
	
	//console.log("response " + response.status)
	//console.log("response " + response.message)
	//if (response.ok) {
	//	
	//} else {
	//	callback({"message": "The request was an error"}, 400)
	//}
	
}
